<template>
    <div class="flex size-full flex-1 flex-col">
        <!-- Background Color -->
        <div class="absolute inset-0 bg-neutral-1000" />

        <div class="relative flex size-full flex-1 flex-col">
            <ProgressTimelineLayout
                v-if="!!loadingHotel && !!trip"
                :items="[
                    {
                        slotName: 'main',
                        icon: IconSparkle,
                        iconClass: '!text-blue-300',
                        iconBorderClass: 'ring-slate-1000',
                        timelineClass: 'bg-slate-1000',
                    },
                ]"
            >
                <template #main>
                    <div class="relative flex size-full flex-col">
                        <div
                            class="relative z-20 flex flex-none flex-col gap-10"
                        >
                            <!-- Header -->
                            <div class="flex flex-col gap-1 text-blue-300">
                                <div class="font-600">
                                    {{ config.public.appName.toLowerCase() }}
                                </div>
                                <div class="font-350">
                                    <span>
                                        Finding the best options for
                                        you<AnimatingLoadingDotsSpan />
                                    </span>
                                </div>
                            </div>

                            <!-- Hotel Info-->
                            <div
                                class="mb-page-gutter flex flex-col items-center justify-center gap-2 bg-neutral-1000"
                            >
                                <Stars
                                    :stars="loadingHotel.stars"
                                    class="fill-neutral-50 text-neutral-50"
                                />
                                <div
                                    class="text-center font-450 text-2xl text-neutral-50"
                                >
                                    {{ loadingHotel.name }}
                                </div>
                                <div class="font-400 text-xs text-neutral-700">
                                    {{
                                        `${loadingHotel.stars}-star hotel · ${trip.location.name}`
                                    }}
                                </div>
                            </div>
                        </div>

                        <div class="relative z-10 w-full flex-1">
                            <!-- Hotel Images -->
                            <div class="absolute inset-0 overflow-hidden">
                                <div class="flex flex-row gap-3 p-3">
                                    <div
                                        v-for="(
                                            column, idx
                                        ) in threeImageUrlColumns"
                                        :key="idx"
                                        :class="[`flex flex-1 flex-col gap-3`]"
                                        :style="animationCssStyles[idx]"
                                    >
                                        <div
                                            v-for="(image, _idx) in column"
                                            :key="image.url"
                                            :class="[`relative w-full`]"
                                        >
                                            <NuxtImg
                                                :src="image.url"
                                                sizes="100px sm:200px"
                                                densities="x1 x2"
                                                :class="[
                                                    `aspect-[200/280] w-full rounded-2xl bg-neutral-800 object-cover`,
                                                ]"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Hotel Images Top Gradient Coverup -->
                            <div
                                class="absolute inset-x-0 top-0 h-96 bg-gradient-to-b from-neutral-1000"
                            />

                            <!-- Hotel Images Bottom Gradient Coverup -->
                            <div
                                class="absolute inset-x-0 bottom-0 h-96 bg-gradient-to-t from-neutral-1000"
                            />
                        </div>
                    </div>
                </template>
            </ProgressTimelineLayout>
        </div>
    </div>
</template>

<script setup lang="ts">
import IconSparkle from '~/components/Icon/IconSparkle.vue'

const tcModalStore = useTripCreationModalStore()
const { loadingHotel, trip } = storeToRefs(tcModalStore)
const config = useRuntimeConfig()

const threeImageUrlColumns = computed(() => {
    if (!loadingHotel.value) return [[], [], []]
    const images = loadingHotel.value.images

    return [
        images.slice(0, Math.ceil(images.length / 3)),
        images.slice(
            Math.ceil(images.length / 3),
            Math.ceil((images.length / 3) * 2)
        ),
        images.slice(Math.ceil((images.length / 3) * 2)),
    ]
})

const randomInitialTranslateYArray = computed(() => {
    return Array.from({ length: 3 }, () => -1 * Math.floor(Math.random() * 200))
})

const randomToTranslateYArray = computed(() => {
    return Array.from({ length: 3 }, () => 0)
})

const animationCssStyles = computed(() => {
    // want something like this: --from-translate-y: 0px; --to-translate-y: 100px; animation:slide 1s ease infinite;
    return Array.from({ length: 3 }, (_, idx) => {
        return {
            '--from-translate-y': `${randomInitialTranslateYArray.value[idx]}px`,
            '--to-translate-y': `${randomToTranslateYArray.value[idx]}px`,
            animation: `slide 10s ease-in-out infinite`,
            animationDirection: `alternate`,
            animationDelay: `0s`,
            transform: `translateY(${randomInitialTranslateYArray.value[idx]}px)`,
        }
    })
})
</script>

<style>
@keyframes slide {
    from {
        transform: translateY(var(--from-translate-y));
    }

    to {
        transform: translateY(var(--to-translate-y));
    }
}
</style>
