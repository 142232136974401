<template>
    <ProgressTimelineLayout
        v-if="trip"
        :items="[
            {
                icon: IconLocationFilled,
                slotName: 'location',
                iconClass: 'text-neutral-800',
            },
            {
                icon: IconSparkle,
                slotName: 'auth',
                iconClass: 'text-blue-500',
            },
        ]"
    >
        <template #location>
            <TripInfoHeader :trip="trip" />
        </template>
        <template #auth>
            <div class="flex size-full flex-col gap-12">
                <ClientOnly class="flex flex-none flex-col gap-8">
                    <span>
                        We've found
                        <span class="font-600"
                            >{{ deals.length }} incredible deal{{
                                deals.length === 1 ? '' : 's'
                            }}</span
                        >
                        for your trip! Verify your email or phone number to
                        proceed.
                    </span>
                    <div class="w-full max-w-sm">
                        <Authentication ui-context="trip_creation_flow" />
                    </div>
                </ClientOnly>

                <div
                    class="relative flex flex-1 flex-col justify-end overflow-hidden"
                >
                    <div
                        class="sticky inset-x-0 bottom-0 flex flex-col rounded-lg bg-blue-100 p-px"
                    >
                        <div
                            class="flex flex-row items-center gap-2 px-4 py-2 text-blue-1000"
                        >
                            <IconSparkle class="size-4" />
                            <div>
                                Your exclusive {{ config.public.appName }} deals
                            </div>
                        </div>
                        <div
                            class="flex flex-col gap-px overflow-hidden rounded-lg"
                        >
                            <div
                                v-for="deal in deals.slice(
                                    0,
                                    Math.min(2, deals.length)
                                )"
                                :key="deal.id"
                                class="flex h-28 w-full flex-row items-center gap-4 bg-neutral-50 p-4"
                            >
                                <div
                                    class="relative aspect-square h-full w-auto flex-00auto overflow-hidden rounded bg-neutral-200 sm:aspect-video"
                                >
                                    <NuxtImg
                                        v-if="deal.hotel.images.length > 0"
                                        :src="deal.hotel.images[0].url"
                                        sizes="126px"
                                        densities="x1 x2"
                                        class="size-full object-cover"
                                    />
                                </div>
                                <div class="flex flex-1 flex-col">
                                    <DealPercentageBadge
                                        v-if="deal.dealPercentage"
                                        class="mb-1 flex flex-00auto self-start sm:hidden"
                                        :deal-percentage="deal.dealPercentage"
                                    />
                                    <div
                                        class="flex flex-row items-center justify-between gap-2"
                                    >
                                        <div
                                            class="line-clamp-1 font-400 text-xl text-neutral-1000"
                                        >
                                            {{ deal.hotel.name }}
                                        </div>

                                        <DealPercentageBadge
                                            v-if="deal.dealPercentage"
                                            class="hidden flex-00auto sm:flex"
                                            :deal-percentage="
                                                deal.dealPercentage
                                            "
                                        />
                                    </div>
                                    <div
                                        class="font-400 text-base text-neutral-1000/50"
                                    >
                                        {{ deal.hotel.stars }}-star hotel
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="absolute inset-0 bg-gradient-to-b from-neutral-50/40 to-neutral-50"
                    />
                </div>
            </div>
        </template>
    </ProgressTimelineLayout>
</template>
<script setup lang="ts">
import IconLocationFilled from '~/components/Icon/IconLocationFilled.vue'
import IconSparkle from '~/components/Icon/IconSparkle.vue'
import TripInfoHeader from '~/components/TripInfoHeader.vue'

const tcModalStore = useTripCreationModalStore()
const { trip, deals } = storeToRefs(tcModalStore)
const tripId = computed(() => trip.value?.id)

const config = useRuntimeConfig()
</script>
