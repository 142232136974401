<template>
    <BottomCardModal
        v-model="isOpen"
        panel-class="pl-2 sm:pl-page-gutter "
        :on-after-leave="tcModalStore.reset"
    >
        <transition name="fade" mode="out-in">
            <TripCreationModalCreationStep
                v-if="step === 'creation'"
                key="creation-step"
            />
            <TripCreationModalLoadingDealsStep
                v-else-if="step === 'loading'"
                key="loading-deals-step"
            />
            <TripCreationModalOptionalAuthStep
                v-else-if="step === 'auth'"
                key="optional-auth-step"
            />
            <TripCreationModalUnsupportedLocation
                v-else-if="
                    step === 'unsupported-location' || step === 'no-deals'
                "
                key="unsupported-location"
            />
        </transition>
        <TripCreationModalDealsFetcher v-if="trip" :trip-id="trip.id" />
    </BottomCardModal>
</template>

<script setup lang="ts">
const tcModalStore = useTripCreationModalStore()
const { step, isOpen, trip } = storeToRefs(tcModalStore)
</script>
