<template>
    <ProgressTimelineLayout
        v-if="!!location"
        :items="[
            {
                icon: IconLocationFilled,
                slotName: 'location',
                iconClass: 'text-neutral-800',
            },
            {
                icon: IconCalendarPlus,
                slotName: 'dates',
                iconClass: 'text-neutral-800',
            },
        ]"
    >
        <template #location>
            <div class="flex flex-col gap-0.5">
                <div
                    class="pr-6 font-400 text-base text-neutral-600 sm:text-lg"
                >
                    Welcome, let's plan your trip to
                </div>
                <div class="font-600 text-2xl text-neutral-1000">
                    {{ location.name }}, {{ location.country }}
                </div>
                <div
                    class="relative mt-6 aspect-video w-full overflow-hidden rounded-2xl bg-neutral-100"
                >
                    <NuxtImg
                        v-if="location.image"
                        sizes="100vw sm:640px"
                        densities="x1 x2"
                        :quality="90"
                        :src="location.image"
                        class="size-full object-cover"
                    />
                </div>
            </div>
        </template>

        <template #dates>
            <div class="flex flex-col gap-4 pb-20">
                <div
                    class="flex flex-col font-400 text-base text-neutral-1000 sm:text-lg"
                >
                    When are you planning to go?
                </div>

                <div
                    class="w-full overflow-hidden rounded-2xl border border-neutral-200"
                >
                    <div
                        class="m-4 flex flex-row border-b border-neutral-200 pb-4 text-sm"
                    >
                        <div class="flex flex-1 flex-col">
                            <div class="font-400 text-neutral-700">
                                Check In
                            </div>
                            <div
                                :class="[
                                    ``,
                                    range.start
                                        ? `text-neutral-1000`
                                        : `text-neutral-400`,
                                ]"
                            >
                                {{
                                    range.start
                                        ? format(range.start, 'MMM dd')
                                        : `Select Date`
                                }}
                            </div>
                        </div>
                        <div
                            class="mx-8 w-px flex-00auto self-stretch bg-neutral-200"
                        />
                        <div class="flex flex-1 flex-col">
                            <div class="font-400 text-neutral-700">
                                Check Out
                            </div>
                            <div
                                :class="[
                                    ``,
                                    range.end
                                        ? `text-neutral-1000`
                                        : `text-neutral-400`,
                                ]"
                            >
                                {{
                                    range.end
                                        ? format(range.end, 'MMM dd')
                                        : `Select Date`
                                }}
                            </div>
                        </div>
                    </div>

                    <div class="relative">
                        <!-- v-model.range typescript error so ignore -->
                        <!-- @vue-ignore -->
                        <VDatePicker
                            v-model.range="range"
                            mode="date"
                            :columns="rowsAndCols[1]"
                            :rows="rowsAndCols[0]"
                            :expanded="true"
                            class=""
                            borderless
                            trim-weeks
                            :min-date="new Date()"
                            color="black"
                        />

                        <div
                            :class="[
                                `absolute inset-0 z-10 flex flex-col items-center justify-center gap-4 bg-neutral-50/90 transition-opacity duration-300`,
                                isPending
                                    ? `opacity-100 `
                                    : `pointer-events-none opacity-0`,
                            ]"
                        >
                            <IconIosSpinner class="size-6" />
                            <div>Creating Trip...</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </ProgressTimelineLayout>
</template>

<script setup lang="ts">
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { addDays, differenceInCalendarDays, format } from 'date-fns'
import { useGrid } from 'vue-screen'

import IconCalendarPlus from '~/components/Icon/IconCalendarPlus.vue'
import IconLocationFilled from '~/components/Icon/IconLocationFilled.vue'
import { postCreateTrip } from '~/lib/api/mutations/postCreateTrip'
import { getTripsQueryKey } from '~/lib/api/queries/getTrips'

const tcModalStore = useTripCreationModalStore()
const { location } = storeToRefs(tcModalStore)

const grid = useGrid('tailwind')

const rowsAndCols = computed(() => {
    if (grid.breakpoint.length === 0) {
        return [2, 1]
    } else {
        return [1, 2]
    }
})

const range = ref<{ start: Date | null; end: Date | null }>({
    start: null,
    end: null,
})

const qc = useQueryClient()
const { mutate: mutateCreateTrip, isPending } = useMutation({
    mutationFn: postCreateTrip,
    onSuccess: (response) => {
        tcModalStore.onCreateTripSuccess(response)
        qc.invalidateQueries({ queryKey: getTripsQueryKey() })
    },
    meta: {
        errorMessage: 'Failed to create trip',
    },
})

watch(range, (newRange) => {
    if (newRange.start && newRange.end) {
        if (differenceInCalendarDays(newRange.start, newRange.end) === 0) {
            newRange.end = addDays(newRange.end, 1)
        }
        const startDateYYYMMDD = format(newRange.start, 'yyyy-MM-dd')
        const endDateYYYMMDD = format(newRange.end, 'yyyy-MM-dd')

        mutateCreateTrip({
            startDate: startDateYYYMMDD,
            endDate: endDateYYYMMDD,
            locationId: location.value!.id!,
        })
    }
})
</script>

<style>
.vc-black {
    --vc-focus-ring: 0 0 0 1px rgba(0, 0, 0, 0.5);
    --vc-accent-50: #f7f7f7;
    --vc-accent-100: #eaeaea;
    --vc-accent-200: #d4d4d4;
    --vc-accent-300: #a3a3a3;
    --vc-accent-400: #737373;
    --vc-accent-500: #525252;
    --vc-accent-600: #404040;
    --vc-accent-700: #262626;
    --vc-accent-800: #171717;
    --vc-accent-900: #0f0f0f;
    --vc-accent-1000: #000000;
}
</style>
