<template>
    <span>{{
        Array.from({ length: numDots })
            .map((idx) => '.')
            .join('')
    }}</span>
</template>

<script setup lang="ts">
const numDots = ref(3)

function performUpdate() {
    numDots.value = numDots.value === 3 ? 0 : numDots.value + 1
}

let interval: NodeJS.Timeout
onMounted(() => {
    interval = setInterval(() => {
        performUpdate()
    }, 300)
})

onUnmounted(() => {
    clearInterval(interval)
})
</script>
