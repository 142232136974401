<template>
    <!-- Wrap your dialog in a `TransitionRoot` to add transitions. -->
    <TransitionRoot
        :show="isOpen"
        as="template"
        @after-leave="
            () => {
                onAfterLeave?.()
            }
        "
    >
        <Dialog
            class="relative z-dialog"
            @close="
                () => {
                    isOpen = false
                }
            "
        >
            <TransitionChild
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
                class="fixed inset-0 bg-neutral-1000/50 backdrop-blur"
            />

            <div
                class="no-scrollbar fixed inset-0 flex w-screen justify-center overflow-y-auto"
            >
                <TransitionChild
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 translate-y-10"
                    enter-to="opacity-100 scale-100 translate-y-0"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 translate-y-10"
                    as="template"
                >
                    <DialogPanel
                        :class="[
                            `relative mt-header flex h-fit min-h-bottom-card-modal-dialog-panel w-full flex-col overflow-hidden rounded-t-3xl bg-neutral-50 px-page-gutter pt-6 sm:max-w-3xl`,
                            panelClass,
                        ]"
                    >
                        <!-- ... -->
                        <button
                            class="absolute right-page-gutter top-6 size-6 rounded-full p-0.5"
                            @click="isOpen = false"
                        >
                            <IconXCircle
                                class="text-neutral-300 hover:text-neutral-400"
                            />
                        </button>

                        <slot />
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'

defineProps<{
    onAfterLeave?: () => void
    panelClass?: string
}>()

const isOpen = defineModel<boolean>()
</script>
