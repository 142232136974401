import {
    createAnonymousUserIfNecessary,
    makeRequest,
} from '~/lib/api/apiHelpers'
import { LoadingHotel, Trip } from '~/types/api'

export interface PostCreateTripRequest {
    locationId: string
    startDate: string
    endDate: string
}

export interface PostCreateTripResponse {
    status: 'success' | 'error'
    trip: Trip
    loadingHotel: LoadingHotel
}

export async function postCreateTrip(
    params: PostCreateTripRequest
): Promise<PostCreateTripResponse> {
    await createAnonymousUserIfNecessary()
    const response = (await makeRequest({
        method: 'POST',
        path: '/trips',
        body: params,
    })) as PostCreateTripResponse
    return response
}
