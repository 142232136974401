<template>
    <div class="flex flex-row items-center">
        <div
            v-tooltip="'When compared to Expedia'"
            :class="[
                `flex flex-row items-center gap-2 rounded-full px-2 py-1`,
                classes,
            ]"
        >
            <IconSparkle class="size-4" />
            <div class="font-500 text-xs">
                Save {{ cached ? `~` : ``
                }}{{ Math.floor(dealPercentage * 100) }}%
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        dealPercentage: number
        cached?: boolean
    }>(),
    {
        cached: false,
    }
)

const badgeColor = computed(() => {
    if (props.cached) {
        return 'default'
    } else if (props.dealPercentage >= 70) {
        return 'amber'
    } else {
        return 'blue'
    }
})

const classes = computed(() => {
    if (props.cached) {
        return 'text-neutral-700 bg-neutral-100'
    } else if (props.dealPercentage >= 70) {
        return 'text-amber-900 bg-amber-100'
    } else {
        return 'text-blue-800 bg-blue-50'
    }
})
</script>
