<template>
    <div v-if="location" class="flex flex-col gap-4 pb-page-gutter">
        <div class="pr-6 font-400 text-2xl text-neutral-1000">
            We couldn't find a room in {{ location.name }}
        </div>
        <div class="font-400 text-base text-neutral-1000/50">
            Unfortunately, we can't find a quality deal for that location and
            date range. We're always looking to give you a truly incredible stay
            at the lowest possible price. Please try another date or
            destination.
        </div>
        <div
            class="mt-4 flex flex-col items-center justify-center gap-4 rounded-lg bg-gradient-to-r from-[#EDE5D6] to-[#D6EDEB] p-4 sm:flex-row sm:justify-start"
        >
            <div
                class="size-8 flex-00auto overflow-hidden rounded-full bg-neutral-50 p-1.5"
            >
                <IconSparkle class="size-full text-[#628E88]" />
            </div>
            <div
                class="w-full max-w-[300px] text-center text-[#304542]/70 sm:text-left"
            >
                Keep exploring destinations to find the best deals available.
            </div>
            <div class="flex flex-1 items-center justify-end">
                <AButton class="flex-00auto" @click="isOpen = false">
                    Explore Destinations
                </AButton>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import AButton from '~/components/AButton.vue'
import IconSparkle from '~/components/Icon/IconSparkle.vue'

const tcModalStore = useTripCreationModalStore()
const { isOpen, location } = storeToRefs(tcModalStore)
</script>
