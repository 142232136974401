<template><div /></template>

<script setup lang="ts">
import { useTripDealsStore } from '~/lib/api/queries/getTripDeals'

const props = defineProps<{ tripId: string }>()

const tcModalStore = useTripCreationModalStore()

const tdStore = useTripDealsStore()
const { deals, status } = storeToRefs(tdStore)

tdStore.setTripId(props.tripId)

watch(
    [deals, status],
    ([newDeals, newStatus]) => {
        tcModalStore.tgdStatusUpdated(newStatus)
        tcModalStore.tgdDealsUpdated(newDeals)
    },
    { deep: true }
)
</script>
